import { toast } from "react-toastify";
import Select from "react-select";
import { CrossIcon2 } from "../Shared/Icons";
import { Button } from "../MiroComponents/Button/Button";
import SelectComponent from "../MiroComponents/SelectComponent/SelectComponent";
import { FaPlus, FaPlusSquare } from "react-icons/fa";

export const PrecalculatedConditionContainer = ({
  conditions,
  setConditions,
  eventOrPage,
  setEventOrPage,
}) => {
  const eventPageOptions = [
    {
      label: "Page",
      value: "Page",
    },
    {
      label: "Event",
      value: "Event",
    },
  ];

  const pageSelectors = [
    { label: "Contains", value: "LIKE" },
    { label: "Not Contains", value: "NOT LIKE" },
    { label: "Equals to", value: "EQUALS TO" },
    { label: "Not Equals to", value: "NOT EQUALS TO" },
  ];
  const removeConditionGroup = (key) => {
    const updatedCondition = conditions?.filter(
      (item) => item?.dataSetType !== key
    );
    setConditions(updatedCondition);
  };

  const handleRemoveCondition = (key, name) => {
    const updatedCondition = conditions
      ?.find((item) => item?.dataSetType === key)
      ?.filters?.filter((item) => item?._id !== name);
    const newConditions = conditions?.map((item) => {
      if (item?.dataSetType === key) {
        return { ...item, filters: updatedCondition };
      } else {
        return item;
      }
    });
    setConditions(newConditions);
  };
  return (
    <>
      <div className="flex flex-column pre-condition-container default-margin-top">
        {conditions?.length > 0 && (
          <div className="default-margin-bottom">
            <h2>Include data when:</h2>
          </div>
        )}
        {conditions?.map((item, idx) => (
          <div
            className="flex default-column-gap default-margin-bottom justify-space-between align-center"
            key={idx}
          >
            <div>
              <SelectComponent
                options={eventPageOptions}
                value={{
                  label: item?.dataSetType,
                  value: item?.dataSetType,
                }}
                placeholder="Select Option"
                icon={null}
                selectWrapperClass="default-border default-padding disabled-acc"
                customClass={`full-width`}
                containerClass="full-width"
                disabled={true}
                // filterOption={(options, input) => {
                //   if (input) {
                //     return options.label.toLowerCase().includes(input.toLowerCase());
                //   }
                //   return options;
                // }}
                // onChange={(client) => {
                //   delete _params.viewId;
                //   setSelectedClient(client);
                //   setSearchParams(_params);
                // }}
              />
            </div>
            <div className="full-width default-border default-padding">
              {item?.filters?.map((fltr, index) => (
                <div
                  key={index}
                  className="pre-condition-sub-containe flex default-column-gap default-margin-bottom justify-space-between default-border default-padding align-center"
                >
                  <div>
                    <span className="operat">{fltr?.matchType}</span>{" "}
                    <span className="operatval neon">{fltr?.value}</span>
                    {item?.dataSetType === "Event" &&
                      fltr?.eventKey &&
                      fltr?.eventValue && (
                        <>
                          <span className=""> WITH </span>
                          <span className="operat">
                            <strong>{fltr?.eventKey}</strong>
                          </span>{" "}
                          <span className="operatval neon">
                            {fltr?.eventValue}
                          </span>
                        </>
                      )}
                    <></>
                  </div>
                  <div
                    onClick={() =>
                      handleRemoveCondition(item?.dataSetType, fltr?._id)
                    }
                    className="inline-flex pointer"
                    style={{ width: "10px" }}
                  >
                    <CrossIcon2 fill="rgba(255, 18, 18, 1)" />
                  </div>
                </div>
              ))}
              <div className="flex justify-end">
                <Button
                  icon={<FaPlus />}
                  className={`smart-button small elipses active`}
                  text={`Add condition`}
                  onClick={() => setEventOrPage(item?.dataSetType)}
                />
              </div>
            </div>
            <div
              className="inline-flex pointer"
              style={{ width: "18px" }}
              onClick={(e) => removeConditionGroup(item?.dataSetType)}
            >
              <CrossIcon2 fill="rgba(255, 18, 18, 1)" />
            </div>
          </div>
        ))}
        <div className="flex justify-end default-margin-top">
          <Button
            icon={<FaPlusSquare />}
            className={`smart-button small elipses active`}
            text={`Add condition group`}
            onClick={() => setEventOrPage("Page")}
          />
        </div>
      </div>
      {eventOrPage !== null && (
        <div className="form-wrapper" id="add-new-test-form">
          <div className="form-wrapper-inner addtestform">
            <div className="card add-new-test-card" id="formcard">
              <button
                className="close-modal"
                onClick={(_) => {
                  setEventOrPage(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#000"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                </svg>{" "}
              </button>
              <h3>Conditions *</h3>
              <div className="card-body p-0">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const location = e.target["location"].value;
                    const contains = e.target["contains"].value;
                    if (!location || !contains) {
                      toast.error("Please fill all the fields");
                      return;
                    }
                    const newFilter = {
                      _id: Date.now(),
                      matchType: contains,
                      value: location,
                    };
                    if (eventOrPage === "Event") {
                      const eventKey = e.target["eventKey"].value;
                      const eventValue = e.target["eventValue"].value;
                      if (eventKey && eventValue) {
                        newFilter.eventKey = eventKey;
                        newFilter.eventValue = eventValue;
                      }
                    }
                    setConditions((prevConditions) => {
                      const existingCondition = prevConditions.find(
                        (item) => item.dataSetType === eventOrPage
                      );
                      return existingCondition
                        ? prevConditions.map((item) =>
                            item.dataSetType === eventOrPage
                              ? {
                                  ...item,
                                  filters: [...item.filters, newFilter],
                                }
                              : item
                          )
                        : [
                            ...prevConditions,
                            {
                              dataSetType: eventOrPage,
                              filters: [newFilter],
                            },
                          ];
                    });
                    setEventOrPage(null);
                  }}
                >
                  <div className="fields-wrapper">
                    <Select
                      onChange={(role) => {
                        setEventOrPage(role?.value);
                      }}
                      value={{
                        label: eventOrPage,
                        value: eventOrPage,
                      }}
                      className="acc-newselectwrapper"
                      classNamePrefix="acc-newselect"
                      placeholder="Page"
                      options={eventPageOptions}
                    />
                  </div>

                  <div className="fields-wrapper">
                    <Select
                      name="contains"
                      className="acc-newselectwrapper"
                      classNamePrefix="acc-newselect"
                      placeholder="Please select condition"
                      options={pageSelectors}
                    />
                  </div>
                  <div className="fields-wrapper">
                    <input
                      className="field-control"
                      name="location"
                      placeholder={`Add ${eventOrPage} name`}
                    />
                  </div>
                  {eventOrPage === "Event" && (
                    <>
                      <div className="fields-wrapper">
                        <input
                          className="field-control"
                          name="eventKey"
                          placeholder={`Event key`}
                        />
                      </div>
                      <div className="fields-wrapper">
                        <input
                          className="field-control"
                          name="eventValue"
                          placeholder={`Event value`}
                        />
                      </div>
                    </>
                  )}
                  <div className="add__new__test__form-footer flex justify-end">
                    <Button
                      className={`smart-button medium elipses active`}
                      type="submit"
                    >
                      Add Now
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
