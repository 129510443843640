import { useMutation } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BigQueryDataSetsComponent } from "../../Shared/BigQueryDataSetsComponent";
import { FilterPrecalculatedValues } from "../../NewComponents/FilterPrecalculatedValues";
import { addDays, endOfDay, format, startOfDay } from "date-fns";
import {
  GETPRECALCULATEDVALUES,
  GETPRECALCULATEDVALUESAOVRPU,
  GETPRECALCULATIONSV2,
} from "../../../Graphql/Mutations";
import { PreCalculatedValues } from "../../Shared/PreCalculatedValues";
import useStore from "../../../Store";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import MyLoader from "../../MiroComponents/PreLoader";

// const mdeData = {
//   weeks: 6,
//   rows: [
//     {
//       users: 194046,
//       transactions: 10537,
//     },
//   ],
//   weeklyusers: 32341,
//   weeklyconversions: 1756,
//   numberofvariants: 2,
//   confidencelevel: 0.95,
//   statisticalpower: 0.8,
//   baselineconversion: 0.054296403945456234,
//   sampleSizePerGroup: 113190,
//   baselineconversionRate: "5.4",
//   mderesult: [
//     {
//       week: 1,
//       numberofvariants: 2,
//       sampleSizePerVariant: 16170,
//       sampleSizeTotal: 32340,
//       mde: "11.80",
//     },
//     {
//       week: 2,
//       numberofvariants: 2,
//       sampleSizePerVariant: 32340,
//       sampleSizeTotal: 64680,
//       mde: "8.30",
//     },
//     {
//       week: 3,
//       numberofvariants: 2,
//       sampleSizePerVariant: 48510,
//       sampleSizeTotal: 97020,
//       mde: "6.70",
//     },
//     {
//       week: 4,
//       numberofvariants: 2,
//       sampleSizePerVariant: 64680,
//       sampleSizeTotal: 129360,
//       mde: "5.80",
//     },
//     {
//       week: 5,
//       numberofvariants: 2,
//       sampleSizePerVariant: 80850,
//       sampleSizeTotal: 161700,
//       mde: "5.20",
//     },
//     {
//       week: 6,
//       numberofvariants: 2,
//       sampleSizePerVariant: 97020,
//       sampleSizeTotal: 194040,
//       mde: "4.70",
//     },
//   ],
// };

export const PreCalculations = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [formPreData, setFormPreData] = useState({
    crformvalues: {
      enabled: true,
      confidencelevel: 0.95,
      statisticalpower: 0.8,
      tailedValue: "1-tailed",
    },
    aovrpuformvalues: {
      enabled: true,
      confidencelevel: 0.95,
      statisticalpower: 0.8,
      tailedValue: "1-tailed",
    },
  });
  const [numberofvariants, setNumberofvariants] = useState(2);
  const selectedClient = useStore((state) => state.selectedClient);
  const viewId = searchParams.get("viewId");
  const _params = Object.fromEntries([...searchParams]);
  const [precalculationsdata, setPrecalculations] = useState(null);
  const [getprecalcultionsv2] = useMutation(GETPRECALCULATIONSV2);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: startOfDay(addDays(new Date(), -45)),
      endDate: endOfDay(addDays(new Date(), -1)),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);

  const callBackWhenClose = () => {
    setShowCalendar(false);
  };
  useEffect(() => {
    // setPrecalculations(null);
  }, [selectionRange]);

  const resetData = () => {
    setPrecalculations(null);
  };

  const getPreCalculations = useCallback(
    async (conditions, otherFilters) => {
      try {
        setPrecalculations({
          loading: true,
        });
        const queryName = getprecalcultionsv2;
        const payload = {
          client: selectedClient?.value?._id,
          numberofvariants: numberofvariants || 2,
          dateRange: {
            startDate: format(selectionRange[0]?.startDate, "yyyyMMdd"),
            endDate: format(selectionRange[0]?.endDate, "yyyyMMdd"),
          },
          dataSetId: viewId,
          filters: conditions,
          otherFilters,
          ...formPreData,
        };
        const {
          data: { getPrecalculatedDataV2 },
          loading,
        } = await queryName({
          variables: {
            payload,
          },
        });
        setPrecalculations({
          loading,
          ...getPrecalculatedDataV2,
        });
      } catch (error) {
        setPrecalculations({
          error,
          loading: false,
        });
        toast.error("Error fetching precalculations");
      }
    },
    [formPreData, selectionRange, selectedClient, viewId, numberofvariants]
  );

  if (!selectedClient) return <NoDataFound />;
  return (
    <React.Fragment>
      <section className="live-reports">
        <div className="live-reports-inner">
          <h1 className="">Pre-Test Analysis/Calculations </h1>

          <div className="client-data-wrapper">
            <div className="client-details">
              <BigQueryDataSetsComponent
                projectId={
                  selectedClient ? selectedClient?.value?.bqClientId : null
                }
                client={selectedClient?.value ?? null}
                viewId={viewId}
                _params={_params}
                setSearchParams={setSearchParams}
              />
            </div>
          </div>
        </div>
      </section>

      {selectedClient?.value?.bqClientId && viewId && (
        <FilterPrecalculatedValues
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
          callBackWhenClose={callBackWhenClose}
          getPreCalculations={getPreCalculations}
          formPreData={formPreData}
          setFormPreData={setFormPreData}
          resetData={resetData}
        />
      )}

      {selectedClient && precalculationsdata && (
        <>
          {precalculationsdata?.loading ? (
            <MyLoader />
          ) : (
            <PreCalculatedValues
              precalculations={precalculationsdata}
              setFormPreData={setFormPreData}
              formPreData={formPreData}
              numberofvariants={numberofvariants}
              setNumberofvariants={setNumberofvariants}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};
