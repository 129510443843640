import { VscLock } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { useMyProfile } from "../../Hooks/useMyProfile";
import { Button } from "../MiroComponents/Button/Button";
import { getCookie } from "../../Libs/getCookie";
import { logOut } from "../../Libs/Utilities";
import { LoaderLogo } from "../Shared/LoaderLogo";

export const NotFound = () => {
  const { myProfile, loading, error } = useMyProfile();
  const is_client = myProfile?.role?.some((rl) => rl?.name === "CLIENT");
  const navigate = useNavigate();
  if (loading) return <LoaderLogo />;
  if (is_client) {
    return (
      <div className="not-authorized-wrapper">
        <div className="not-authorized-inner">
          <h2>
            This is a legacy dashboard and no longer maintained. <br />
            <br />
            Use the new dashboard instead.
          </h2>
          <Button
            onClick={(e) => {
              const token = getCookie("codebase_token");
              if (token) {
                document.cookie =
                  "codebase_token=; path=/; max-age=0; secure; SameSite=Lax";
                window.location.href = `${process.env.REACT_APP_CLIENT_APP_URL}/login/authenticate/${token}`;
              }
            }}
            className="
                smart-button medium default-margin-top full-width active
                "
          >
            Go to New Dashboard
          </Button>
          <Button
            className="smart-button medium default-margin-top full-width"
            text="Logout"
            onClick={(e) => {
              logOut();
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="not-authorized-wrapper">
      <div className="not-authorized-inner">
        <h2>
          Page you are looking for is not found. <br />
          <br />
          Please check the URL or contact support.
        </h2>
        <Button
          onClick={(e) => {
            navigate(-1);
          }}
          className="
                smart-button medium default-margin-top full-width active
                "
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};
