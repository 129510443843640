import Select from "react-select";
import {
  ABCompareIcon,
  CheckBoxMarkIcon,
  CrossIcon,
  FilterIcon,
} from "../Shared/Icons";
import { DateRangeSelectorComponent } from "../Shared/DateRangeSelectorComponent";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../Store";
import { PrecalculatedConditionContainer } from "./PrecalculatedConditionContainer";
import { Button } from "../MiroComponents/Button/Button";
import { OperatorValues } from "../../Libs/Utilities";
import { ReportFilterValues } from "../../Libs/ReportFiltersValues";

export const FilterPrecalculatedValuesComponent = ({
  showCalendar,
  setShowCalendar,
  selectionRange,
  setSelectionRange,
  callBackWhenClose,
  getPreCalculations,
  formPreData,
  setFormPreData,
  resetData,
}) => {
  const [eventOrPage, setEventOrPage] = useState(null);
  const [conditions, setConditions] = useState([]);
  const [dimensionFilterExpression, setDimensionFilterExpression] = useState(
    []
  );

  const getSelectValue = (selectedSegments, values) => {
    if (!selectedSegments) return null;
    return {
      label: values?.find(
        (val) =>
          val?.toLowerCase() ===
          selectedSegments?.filter?.stringFilter?.value?.toLowerCase()
      ),
      value: selectedSegments?.filter?.stringFilter?.value,
    };
  };

  const removeSegment = useCallback((fieldName) => {
    const newArr = [...dimensionFilterExpression];
    const fieldNameIndex = newArr?.findIndex(
      (f) => f?.filter?.fieldName === fieldName
    );
    if (fieldNameIndex > -1) {
      newArr.splice(fieldNameIndex, 1);
    }
    setDimensionFilterExpression(newArr);
  });

  const createDimensionFilters = useCallback(
    (value, key, filterType, matchType) => {
      const filterObject = {
        filter: {
          fieldName: key,
        },
      };
      if (filterType === "string") {
        filterObject.filter.stringFilter = {
          matchType: matchType.toUpperCase(),
          value: value?.value,
        };
      }
      const newArr = [...dimensionFilterExpression];
      const fieldName = newArr?.findIndex((f) => f?.filter?.fieldName === key);
      if (fieldName > -1) {
        newArr[fieldName] = filterObject;
      } else {
        newArr.push(filterObject);
      }
      setDimensionFilterExpression(newArr);
    },
    [dimensionFilterExpression]
  );

  return (
    <>
      <section className="selected-test-content live-reports-content">
        <div className="experiment-data-inner">
          <div className="experiment-data-navbar">
            <div className="experiment-data-dropdown-pane properties-dropdown">
              <div className="experiment-dropdown-item">
                <span className="experiment-dropdown-text">
                  <div className="checkbox-wrapper-4">
                    <input
                      className="inp-cbx"
                      id="revenueToUSD"
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          createDimensionFilters(
                            {
                              label: "USD",
                              value: "usd",
                            },
                            "revenueCurrency",
                            "string",
                            "EXACT"
                          );
                        } else {
                          removeSegment("revenueCurrency");
                        }
                      }}
                      checked={
                        getSelectValue(
                          dimensionFilterExpression?.find(
                            (val) =>
                              val?.filter?.fieldName === "revenueCurrency"
                          ),
                          ["USD"]
                        )?.value === "usd"
                          ? true
                          : false
                      }
                    />
                    <label className="cbx" htmlFor="revenueToUSD">
                      <span>
                        <CheckBoxMarkIcon />
                      </span>
                      <span>Convert Revenue to USD</span>
                    </label>
                  </div>
                </span>
              </div>
            </div>

            <div className="date-selector">
              <DateRangeSelectorComponent
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}
                selectionRange={selectionRange}
                setSelectionRange={setSelectionRange}
                className="dark-calendar"
                classNameWrapper="dark-calendar-wrapper showpredefinedranges"
                callBackWhenClose={callBackWhenClose}
                showDays={true}
                color="#fff"
              />
            </div>
          </div>

          {/* <div className="results-filter-wrapper flex align-center justify-space-between flex-wrap default-margin-top">
            <div className="inline-flex align-center">
              <div className="inline-flex">
                {pretypes.map((item, index) => (
                  <Button
                    key={index}
                    className={`smart-button medium ${
                      formPreData?.key === item.key ? "active" : ""
                    }`}
                    text={item.title}
                    type="button"
                    onClick={(e) => {
                      setFormPreData(item);
                      resetData();
                    }}
                  />
                ))}
              </div>
            </div>
          </div> */}
        </div>

        <div className="default-margin-bottom">
          <PrecalculatedConditionContainer
            eventOrPage={eventOrPage}
            setEventOrPage={setEventOrPage}
            setConditions={setConditions}
            conditions={conditions}
          />
        </div>
        <div
          className="experiment-data-navbar flex-column"
          style={{
            borderTop: "0",
          }}
        >
          <div>
            <h1 className="margin-bottom-auto"> Test Filters</h1>
          </div>
          <div
            className="experiment-data-dropdown-pane properties-dropdown"
            style={{
              maxWidth: "100%",
              borderRight: "0",
            }}
          >
            {ReportFilterValues?.map((filter, i) => {
              return (
                <div className="experiment-dropdown-item" key={i}>
                  <span className="experiment-dropdown-text">
                    <Select
                      placeholder={filter?.label}
                      value={getSelectValue(
                        dimensionFilterExpression?.find(
                          (val) => val?.filter?.fieldName === filter?.fieldName
                        ),
                        filter?.values
                      )}
                      onChange={(val) => {
                        createDimensionFilters(
                          val,
                          filter?.fieldName,
                          filter?.filterType,
                          filter?.matchType
                        );
                        // selectSegments(
                        //   segment?.key,
                        //   val,
                        //   selectedSegments,
                        //   "=="
                        // );
                      }}
                      // menuIsOpen={true}
                      // isDisabled={platform === "BQ"}
                      className="acc-newselectwrapper"
                      classNamePrefix="acc-newselect"
                      options={filter?.values?.map((val) => {
                        // console.log({
                        //   label: val,
                        //   value: val?.toLowerCase(),
                        // });
                        return {
                          label: val,
                          value: val?.toLowerCase(),
                        };
                      })}
                    />
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="default-margin-top  justify-end flex">
          <div className="segment-data align-center inline-flex">
            {/* <div className="segment-btn default-margin-bottom"> */}
            <Button
              className="smart-button full-width medium active"
              text={`Calculate`}
              icon={<FilterIcon fill="#000" />}
              onClick={(e) => {
                e.preventDefault();
                getPreCalculations(conditions, dimensionFilterExpression);
              }}
            />

            {/* </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export const FilterPrecalculatedValues = React.memo(
  FilterPrecalculatedValuesComponent
);
