import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { GETBIGQUERYDATASETS } from "../../Graphql/Queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import { ClientPropertiesList } from "../NewComponents/ClientPropertiesList";
import MyLoader from "../MiroComponents/PreLoader";

export const BigQueryDataSetsComponent = ({
  projectId,
  client,
  _params,
  setSearchParams,
  viewId,
}) => {
  const [dataSetsOptions, setDataSetsOptions] = useState({
    loading: true,
  });
  const [getdatasets] = useLazyQuery(GETBIGQUERYDATASETS);
  const getDataSetsQuery = useCallback(async () => {
    if (client?.defaultDataSet) {
      _params.viewId = client?.defaultDataSet;
      setSearchParams(_params);
    }
    try {
      const {
        data: { getBiQueryDataSets },
        loading,
      } = await getdatasets({
        variables: {
          projectId,
        },
      });
      setDataSetsOptions({
        loading,
        getBiQueryDataSets,
      });
    } catch (error) {
      setDataSetsOptions({
        error,
        loading: false,
      });
    }
  }, [projectId, client]);

  useEffect(() => {
    if (projectId) {
      getDataSetsQuery();
    }
  }, [projectId, getDataSetsQuery]);

  if (!projectId)
    return (
      <div>
        <h3
          style={{
            color: "#fff",
          }}
        >
          No Big Query ClientID found for this property.
        </h3>
      </div>
    );
  if (dataSetsOptions?.loading) return <MyLoader />;
  if (dataSetsOptions?.error)
    return <div>{dataSetsOptions?.error?.message}</div>;
  return (
    <div className="data-sets-loaded">
      <h2 className="">DataSets:</h2>
      <ClientPropertiesList
        _params={_params}
        setSearchParams={setSearchParams}
        selectedViewId={viewId}
        listCount={2}
        properties={dataSetsOptions?.getBiQueryDataSets?.map((value) => ({
          platform: "BQ",
          propertyname: value,
          viewId: value,
        }))}
      />
    </div>
  );
};
